import { Prop, Component, Vue } from '@/lib/decorator';

import { ref } from 'vue';

import formValidate from '@/lib/formFactory/validate';

@Component({})
export default class FormEditor extends Vue {
  @Prop() typeAction!: string;
  @Prop() view!: string;

  form = ref(null);
  validate = formValidate(this.form);

  add() {
    throw new Error('Method add');
  }

  edit() {
    throw new Error('Method edit');
  }

  async save() {
    const valid = await this.validate.validate(true);

    if (!valid) {
      return;
    }

    if (this.typeAction === 'add') {
      await this.add();
    }

    if (this.typeAction === 'edit') {
      await this.edit();
    }
  }
}
