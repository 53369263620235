
import { Component, Vue, Watch } from '@/lib/decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import ArrowReturn from '@/components/icons/ArrowReturn.vue';
import DatePicker from '@/components/form/DatePickerFilter.vue';
import AddBlock from './Add.vue';
import EditBlock from './Edit.vue';
import DiagramBlock from '@/components/EChart.vue';
import IconFont from '@/components/icons/IconFont.vue';

import SplitModule from '@/store/settings/split';
import SplitChartModule from '@/store/settings/split/chart';
import SettingsModule from '@/store/settings';
import userModule from '@/store/user';
import AppTable from '@/components/ui/table/Table.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import StatusActive from '@/components/table-items/StatusActive.vue';
import SplitEntityModule from '@/store/settings/split/entity';

import { FilterEntityModel } from '@/lib/layouts/page/filter.interface';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { SplitChartOptions, SplitChartItem } from '@/store/settings/split/chart';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    AppTable,
    TabsNav,
    AppHeaderBtnAdd,
    ArrowReturn,
    AddBlock,
    EditBlock,
    DiagramBlock,
    DatePicker,
    IconFont,
    StatusActive,
  },
})
export default class SplitMain extends Vue {
  activeRow = null;

  get isLoading(): boolean {
    return SplitEntityModule.isLoading;
  }

  get filterModel(): FilterEntityModel {
    return SplitModule.filter.filterSettings.filterModel.date;
  }

  get tabsNav(): TabsNavInterface {
    return SettingsModule.tabsNav;
  }

  get currentTab(): string {
    return SettingsModule.allTabs.split.id;
  }

  get canIsEdit(): boolean {
    return userModule.userHasPermission('CAN_EDIT_SPLIT');
  }

  get isAdd(): boolean {
    return SettingsModule.isAdd;
  }

  get isEdit(): boolean {
    return SettingsModule.isEdit;
  }

  get settings(): PageInterface {
    return SplitModule.pageSettings;
  }

  get chartOptions(): SplitChartOptions {
    return SplitChartModule.chart;
  }

  get chartValues(): {} | Record<string, SplitChartItem> {
    return SplitChartModule.chartValues;
  }

  @Watch('activeRow')
  changeColorRow(newActiveElement: HTMLElement, previousActiveElement: HTMLElement): void {
    if (newActiveElement) {
      newActiveElement.classList.add('app-table-body-row--selected');
    }

    if (previousActiveElement) {
      previousActiveElement.classList.remove('app-table-body-row--selected');
    }
  }

  cancelEdit(): void {
    this.activeRow = null;
  }

  toAdd(): void {
    SplitModule.updateIsAdd(true);
  }

  sort(header: { id: string; sort: { value: string } }): void {
    SplitModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  toEdit(id: string): void {
    const table = this.$refs.table as InstanceType<typeof AppTable>;

    if (table) {
      this.activeRow = table.$el.querySelector(`.app-table-body-row--id-${id}`);
    }

    SplitModule.updateIsEdit({ isEdit: true, id: id.toString() });
  }

  returnBefore(): void {
    this.cancelEdit();
    SplitModule.updateIsAdd(false);
    SplitModule.updateIsEdit({ isEdit: false, id: '' });
  }

  async handleDate(
    params: { key: string; date: { day: number; month: string; year: string } },
    action: string
  ): Promise<void> {
    await this.$store.dispatch(action, params, { root: true });
    await this.updateList();
  }

  async resetFilters(): Promise<void> {
    await SplitModule.setPageToBegin();
    await SplitModule.filter.resetFilter();
    await SplitModule.getList();
    await SplitModule.getStatistics();
  }

  async updateList(): Promise<void> {
    await SplitModule.setPageToBegin();
    await SplitModule.filter.updateFilter();
    await SplitModule.getList();
    await SplitModule.getStatistics();
  }

  selectAmount(value: string): void {
    SplitModule.updatePageAmountItems(value);
  }

  created(): void {
    SplitModule.init();
  }

  mounted(): void {
    SettingsModule.initTabsItems();
  }
}
