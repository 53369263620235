/**
 * Split entity store.
 *
 */

import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import EntityBaseModule from '@/store/entity';
import store from '@/store';
import { addNewSplit, updateSplit } from '@/api/split';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import SplitModule from './index';

import { strings } from '@/lib/stringConst';
import { CatchFormResponse } from '@/interfaces/shared';

export const MODULE_NAME = 'splitEntity';

export interface SplitModel {
  id?: number;
  name: string;
  isArchived: boolean;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SplitEntityModule extends EntityBaseModule {
  model: SplitModel = { name: '', isArchived: false };
  isLoading = false;

  constructor(module: SplitEntityModule) {
    super(module);
  }

  @Mutation
  SET_NAME(value: string) {
    this.model.name = value;
  }

  @Mutation
  SET_IS_ARCHIVED(value: boolean) {
    this.model.isArchived = value;
  }

  @Action({ rawError: true })
  async getInfoById() {
    try {
      const splitItems = Object.values((SplitModule.pageSettings.table as TableApiInterface<SplitModel>).rows);
      const splitItem = splitItems.find((row) => row.id?.toString() === this.id.toString());

      if (!splitItem) {
        throw new Error('Not found');
      }

      this.setInfo(splitItem);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse)?.response?.data?.message ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  setInfo(info: SplitModel) {
    this.context.commit('SET_NAME', info.name);
    this.context.commit('SET_IS_ARCHIVED', info.isArchived);
    this.updateIsLoading(false);
  }

  @Action({ rawError: true })
  async addNew(data: { name: string }) {
    try {
      const result = await addNewSplit(data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Блокировка создана', type: 'ok' });
        await SplitModule.getList();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse<string, string>).response.data.errors.fields,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  async saveUpdate(data: { name: string; isArchived: boolean }) {
    try {
      this.updateIsLoading(true);

      const split: Record<string, string> = { name: data.name, isArchived: data.isArchived ? '1' : '0' };
      const result = await updateSplit(this.id, split);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: strings.SUCCESS_UPDATE, type: 'ok' });
        await SplitModule.getList();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      return result?.result?.split;
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse<string, string>).response.data.errors.fields,
        type: 'fail',
      });
    } finally {
      this.updateIsLoading(false);
    }
  }

  @Action({ rawError: true })
  resetModel() {
    this.context.commit('SET_NAME', '');
    this.context.commit('SET_IS_ARCHIVED', false);
    this.updateIsLoading(false);
  }
}

export default getModule(SplitEntityModule);
