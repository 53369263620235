/**
 * Split store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';

import PageEntity from '@/lib/layouts/page/pageEntity';
import { getSplitStatisticList, getSplitStatisticAllList } from '@/api/split';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import SplitEntityModule from '@/store/settings/split/entity';
import SplitChartModule from '@/store/settings/split/chart';
import SettingsModule from '@/store/settings';
import filterModel from './filter';
import SplitFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { prepareList } from '@/lib/Split';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'split';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SplitModule extends PageBaseModule {
  filter: Filter;

  constructor(module: SplitModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Блокировки');
    page.values.actionPagination = 'split/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(SplitFilter);
    this.filter.setFilterName('splitFilter');
    this.filter.setTemplateClassName('template-sm');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.splitSort = JSON.stringify(sorts);
  }

  @Action({ rawError: true })
  async init() {
    this.context.commit('SET_SORT', window.localStorage.splitSort ? JSON.parse(window.localStorage.splitSort) : '');
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.getList();
    await this.getStatistics();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter = this.filter.filterSettings.filter;

      const result = await getSplitStatisticList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);
      await this.setList(result);
    } catch (error) {
      this.context.commit('SET_SORT', '');
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getStatistics() {
    try {
      const sort = 'sort[0][id]=cnt&sort[0][value]=DESC&';
      const filter = this.filter.filterSettings.filter;

      const result = await getSplitStatisticAllList(sort, filter);
      SplitChartModule.createDiagram(result.rows);
    } catch (error) {
      this.context.commit('SET_SORT', '');
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    this.context.commit('SET_TABLE', prepareList(table, this));
  }

  @Action({ rawError: true })
  updateIsAdd(isAdd: boolean) {
    SplitEntityModule.resetModel();
    SettingsModule.SET_IS_EDIT(false);
    SplitEntityModule.SET_ID('');
    SettingsModule.SET_IS_ADD(isAdd);
  }

  @Action({ rawError: true })
  async updateIsEdit(params: { isEdit: boolean; id: string }) {
    await SplitEntityModule.resetModel();
    await SplitEntityModule.SET_ID(params.id);
    SettingsModule.SET_IS_ADD(false);
    SettingsModule.SET_IS_EDIT(params.isEdit);

    if (params.id && params.id.length > 0) {
      await SplitEntityModule.getInfoById();
    }
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getList();
  }
}

export default getModule(SplitModule);
