/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class SplitFilter {
  filterHandlers = {
    update: 'splitFilter/updateFilter',
    reset: 'splitFilter/resetFilter',
    resetState: 'splitFilter/resetFilterState',
    removeSelected: 'splitFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createDateIntervalPickerEntity('date');
    filter.setName('date', 'picker_date');
    filter.setPreviewName('date', 'Дата');
    filter.setTitle('date', '');
    filter.setDateIntervalList('date', {
      startDate: {
        key: 'periodStart',
        type: 'date-picker',
        title: '',
        placeholder: 'дд/мм/гг',
        value: '',
        action: 'splitFilter/updateStartDate',
      },
      endDate: {
        key: 'periodEnd',
        type: 'date-picker',
        title: '',
        placeholder: 'дд/мм/гг',
        value: '',
        action: 'splitFilter/updateEndDate',
      },
    });

    this.filterModel = filter.filterModel;
  }
}
