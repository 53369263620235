
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './_form.vue';
import Loading from '@/components/Loading.vue';

import SplitModule from '@/store/settings/split';
import SplitEntityModule, { SplitModel } from '@/store/settings/split/entity';

@Component({
  components: {
    FormBlock,
    Loading,
  },
})
export default class RegionsEntityEdit extends Form {
  get isLoading(): boolean {
    return SplitEntityModule.isLoading;
  }

  returnBefore(): void {
    this.$emit('cancel');
    SplitModule.updateIsAdd(false);
  }

  saved(savedSplit: SplitModel): void {
    this.$emit('saved', savedSplit);
  }
}
