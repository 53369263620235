
import { Prop, Component } from '@/lib/decorator';
import FormEditor from '@/lib/FormEditor';

import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import FormInput from '@/components/form/Input/index.vue';
import FormButton from '@/components/form/Button/index.vue';

import SplitEntityModule from '@/store/settings/split/entity';
import { strings } from '@/lib/stringConst';
import SettingsModule from '@/store/settings';

@Component({
  components: {
    FormInput,
    FormButton,
    FormSimpleSwitch,
  },
  emits: ['save', 'returnBefore'],
})
export default class SplitEntityForm extends FormEditor {
  @Prop() typeAction!: string;

  get text(): typeof strings {
    return strings;
  }

  get model(): { name: string; isArchived: boolean } {
    return SplitEntityModule.model;
  }

  get isAdd(): boolean {
    return SettingsModule.isAdd;
  }

  get isEdit(): boolean {
    return SettingsModule.isEdit;
  }

  returnBefore(): void {
    this.$emit('returnBefore');
  }

  async add(): Promise<void> {
    try {
      await SplitEntityModule.addNew({ name: this.model.name });
      this.returnBefore();
    } catch (e) {
      console.debug('error');
    }
  }

  async edit(): Promise<void> {
    try {
      const savedSplit = await SplitEntityModule.saveUpdate({ name: this.model.name, isArchived: this.model.isArchived });
      this.$emit('saved', savedSplit);
    } catch (e) {
      console.debug('error');
    }
  }
}
