import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import PageBaseModule from '@/store/page';

/**
 * Returns processed split info for table
 *
 * @param {Object} table shops data
 * @param {Object} state component's state
 * @returns {Object} tableInfo
 */
export function prepareList(table: TableApiInterface, state: PageBaseModule): Partial<UITable> {
  const uiTable = new UITable(table);
  const sorts = Object.values(state.pageSettings.sort) as { sort: string; key: string }[];

  return uiTable
    .setProperties('isArchived', { id: 'isArchived', name: 'Активность', align: 'center' })
    .setProperty('cnt', 'sortable', true)
    .setSortableValues(sorts)
    .getTable();
}
