import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-layout-relative cp-layout-height-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_block = _resolveComponent("form-block")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_block, {
      "type-action": "edit",
      onSaved: _ctx.saved,
      onReturnBefore: _ctx.returnBefore
    }, null, 8, ["onSaved", "onReturnBefore"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_loading, {
          key: 0,
          "stroke-color": "#6E258B"
        }))
      : _createCommentVNode("", true)
  ]))
}