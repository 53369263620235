/**
 * Split chart store.
 *
 */

import { Module, Mutation, Action, getModule, VuexModule } from 'vuex-module-decorators';
import store from '@/store';

export const MODULE_NAME = 'splitChart';

export interface SplitChartItem {
  itemStyle: {
    color: string;
  };
  name: string;
  value: string;
}

interface SplitChartSeriesItem {
  [index: string]:
    | string
    | string[]
    | Record<string, string | number | Record<string, string | number | boolean>>
    | SplitChartItem[];
}

export interface SplitChartOptions {
  [index: string]: Record<string, string | Record<string, string>> | SplitChartSeriesItem[];
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SplitChartModule extends VuexModule {
  chartOptions: SplitChartOptions = {
    textStyle: {
      color: '#515184',
    },
    labelLine: {
      lineStyle: {
        color: '#515184',
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}',
    },
    series: [
      {
        name: '',
        type: 'pie',
        // radius: '55%',
        radius: ['30%', '70%'],
        center: ['50%', '50%'],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 4,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '15',
            fontWeight: 'bold',
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(155, 155, 199, 0.5)',
          },
        },
        data: [],
      },
    ],
  };
  chartColors = ['#7CA5E2', '#E27F7F', '#AD90D3', '#74D695', '#FFC42B', '#AFEEEE'];
  chart: SplitChartOptions = {};
  chartValues: Record<string, SplitChartItem> | {} = {};

  constructor(module: SplitChartModule) {
    super(module);
  }

  @Mutation
  SET_CHART(chartValue: Record<string, SplitChartItem>) {
    this.chart = Object.assign({}, this.chartOptions);
    (this.chart.series as SplitChartSeriesItem[])[0].data = Object.values(chartValue);
    this.chartValues = Object.assign({}, chartValue);
  }

  @Action({ rawError: true })
  createDiagram(info: { name: string; percent: string }[]) {
    let othersPercent = 0.0;
    const chartItems: Record<string, SplitChartItem> | {} = {};
    for (const [index, item] of Object.entries(info)) {
      if (parseInt(index) > 4) {
        othersPercent += parseFloat(item.percent);
        continue;
      }

      (chartItems as Record<string, SplitChartItem>)[index] = {
        itemStyle: {
          color: this.chartColors[parseInt(index)],
        },
        value: item.percent,
        name: `${item.name} (${item.percent}%)`,
      };
    }

    if (Object.values(info).length > 4) {
      (chartItems as Record<string, SplitChartItem>)[Object.values(info).length] = {
        value: String(othersPercent),
        name: `Прочее (${othersPercent}%)`,
        itemStyle: {
          color: this.chartColors[5],
        },
      };
    }

    this.SET_CHART(chartItems);
  }
}

export default getModule(SplitChartModule);
