import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-layout__bg cp-layout-form__wrapper" }
const _hoisted_2 = { class: "cp-margin__tb cp-form__row cp-page__padding cp-flex cp-flex--justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_simple_switch = _resolveComponent("form-simple-switch")!
  const _component_form_button = _resolveComponent("form-button")!

  return (_openBlock(), _createElementBlock("form", {
    ref: "form",
    class: "cp-margin__top-40 cp-margin__bottom-40 cp-page__padding-sm",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_form_input, {
        modelValue: _ctx.model.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
        "max-length": 255,
        label: "Причина блокировки",
        required: "",
        class: "cp-width__max--480"
      }, null, 8, ["modelValue"]),
      (_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_form_simple_switch, {
            key: 0,
            modelValue: _ctx.model.isArchived,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.isArchived) = $event)),
            label: "В архиве",
            horizontal: "",
            reverse: "",
            "text-highlight": ""
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (_ctx.isAdd)
          ? (_openBlock(), _createBlock(_component_form_button, {
              key: 0,
              text: _ctx.text.btnAdd,
              type: 'submit',
              class: "cp-margin__r--sx cp-margin__b--small"
            }, null, 8, ["text"]))
          : (_ctx.isEdit)
            ? (_openBlock(), _createBlock(_component_form_button, {
                key: 1,
                text: _ctx.text.btnSave,
                type: 'submit',
                class: "cp-margin__r--sx cp-margin__b--small"
              }, null, 8, ["text"]))
            : _createCommentVNode("", true),
        _createVNode(_component_form_button, {
          text: _ctx.text.btnCancel,
          class: "cp-margin__b--small",
          bordered: true,
          onClick: _ctx.returnBefore
        }, null, 8, ["text", "onClick"])
      ])
    ])
  ], 544))
}