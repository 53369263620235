
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './_form.vue';

import SplitModule from '@/store/settings/split';

@Component({
  components: {
    FormBlock,
  },
})
export default class SplitEntityAdd extends Form {
  returnBefore() {
    SplitModule.updateIsAdd(false);
  }
}
